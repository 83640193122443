import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form,
    FloatingLabel,
    Table,
    Pagination
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ModalErro from './ModalErro';
import React, { useEffect } from 'react';
import global from '../global';

ModalSelecionarVendedor.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    setVendedorSelecionado: PropTypes.func.isRequired,
    vendedores: PropTypes.array,

}

export default function ModalSelecionarVendedor({ showModal, setShowModal, setVendedorSelecionado, vendedores}) {

    const [codVendedorDigitado, setCodVendedorDigitado] = React.useState("");
    const [nomeVendedorDigitado, setNomeVendedorDigitado] = React.useState("");
    const [vendedoresModal, setVendedoresModal] = React.useState(vendedores);

    const [paginaAtual, setPaginaAtual] = React.useState(1);

    console.log("vendedoresModal", vendedoresModal);

    useEffect(() => {

        console.log("teste", nomeVendedorDigitado);

        if(nomeVendedorDigitado == '' && codVendedorDigitado == ''){
            setVendedoresModal(vendedores);
        }else{

            let vendedoresAux = [];
            vendedores.map((item) => {
                console.log(item.idVendedor);
                console.log(codVendedorDigitado);

                if(item.nomeVendedor.toString().includes(nomeVendedorDigitado) && nomeVendedorDigitado != ''){
                    vendedoresAux.push(item);
                }else if(item.idVendedor.toString().includes(codVendedorDigitado) && codVendedorDigitado != ''){
                    vendedoresAux.push(item);
                }
            })

            console.log(vendedoresAux);

            setVendedoresModal(vendedoresAux);
        }

    }, [nomeVendedorDigitado, codVendedorDigitado]);

    return (

        <Modal
        show={showModal}
        onHide={() => { setShowModal(false) }}
        centered
        size='lg'
        >
            <Modal.Header closeButton={true}>
                
                <Modal.Title>
                    
                    <h4>Selecione o vendedor desta venda:</h4> 
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>

                    <Row>
                        <Col>
                            
                            <FloatingLabel
                                label="Codigo vendedor: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type={'text'}
                                    value={codVendedorDigitado}
                                    onChange={(e) => {
                                        setCodVendedorDigitado(e.target.value)
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                        <Col>
                        
                            <FloatingLabel
                                label="Nome vendedor: "
                                className="mb-3"
                            >
                                <Form.Control 
                                    type={'text'}
                                    value={nomeVendedorDigitado}
                                    onChange={(e) => {
                                        setNomeVendedorDigitado(e.target.value)
                                    }}
                                />
                            </FloatingLabel>
                        
                        </Col>
                    </Row>
                    <Row>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nome</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    vendedoresModal != null && vendedoresModal != undefined && vendedoresModal.length > 0 &&
                                    vendedoresModal.slice((paginaAtual - 1) * global.VALOR_POR_PAGINA, ((paginaAtual) * global.VALOR_POR_PAGINA) - 1).map(item => (
                                        <tr key={item.idVendedor}>
                                            <td>{item.idVendedor}</td>
                                            <td>{item.nomeVendedor}</td>
                                            <td>
                                                <div className="d-grid gap-2">
                                                    <Button
                                                        variant='success'
                                                        onClick={() => {
                                                            setVendedorSelecionado(item);
                                                            setShowModal(false);
                                                        }}
                                                    >
                                                        Selecionar
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    {vendedoresModal != null && vendedoresModal != undefined && vendedoresModal.length > 0 &&
                        <Container 
                            fluid 
                            style={{alignContent: "center", alignItems: "center", alignSelf: "center", marginTop: "5%"}} 
                        >
                            
                            <Pagination style={{alignContent: "center", alignItems: "center", alignSelf: "center", justifyContent: "center", justifyItems: "center"}} size='lg' >
                                <Pagination.First 
                                    onClick={() => {
                                        if(paginaAtual > 1){
                                            setPaginaAtual(1);
                                        }
                                    }} 
                                />
                                <Pagination.Prev 
                                    onClick={() => {
                                        if(paginaAtual > 1){
                                            if(paginaAtual > 1){
                                                setPaginaAtual(paginaAtual - 1);
                                            }else{
                                                setPaginaAtual(1);
                                            }
                                        }
                                    }}
                                />
                                <Pagination.Item active>{paginaAtual}</Pagination.Item>
                                <Pagination.Next 
                                    onClick={() => {
                                        let totalPaginas = Math.ceil(vendedoresModal.length / global.VALOR_POR_PAGINA);
                                        if(paginaAtual + 1 <= totalPaginas){
                                            setPaginaAtual(paginaAtual + 1);
                                        }
                                    }}
                                />
                                <Pagination.Last 
                                    onClick={() => {
                                        let totalPaginas = Math.ceil(vendedoresModal.length / global.VALOR_POR_PAGINA);
                                        setPaginaAtual(totalPaginas);
                                    }}
                                />
                            </Pagination>
                        </Container>
                    }
                    <Row>
                        <Col>
                        
                            <div className="d-grid gap-2">
                                <Button
                                variant="danger"
                                onClick={() => {
                                    setShowModal(false);
                                }}
                                >Cancelar</Button>{' '}

                            </div>

                        </Col>
                    </Row>
                </Container>
                
            </Modal.Body>
        </Modal>

    );

}
